// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
    createNativeStackNavigator,
    NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { useSelector, useDispatch } from 'react-redux';

import { LoginScreen } from '../screens/AuthScreen/LoginScreen';
import { ForgotPasswordScreen } from '../screens/AuthScreen/ForgotPasswordScreen';

import { DrawerNavigation } from './DrawerNavigation';
import { ClientDrawerNavigation } from './ClientDrawerNavigation';

import { Loading } from '../components/Loading';
import { api } from '../services/api';
import { loginAuth } from '../store/modules/user/actions';
import { clientsRefreshToken } from '../services/clients';
import { updateClienteRevendaPro, updateRevendaPro } from '../services/vehicles';

type RootStackParamList = {
    LoginScreen: undefined;
    ForgotPasswordScreen: undefined;
    DrawerNavigation: undefined;
    ClientDrawerNavigation: undefined;
    NewClientScreen: undefined;
    ManageClientsScreen: undefined;
    EditPerfilScreen: undefined;
};

export interface NavigationProps {
    navigation: NativeStackNavigationProp<RootStackParamList, any>;
}

const { Navigator, Screen } = createNativeStackNavigator<RootStackParamList>();

export function Routes() {
    const userInfo = useSelector((state: any) => state.user.user);
    const screenOptions = { headerShown: false };
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    async function setTokenDefault() {
        const body = {
            refresh: userInfo?.tokenRefresh,
            type: userInfo?.user?.type,
        };

        const response = await clientsRefreshToken(body);
        api.defaults.headers.common['x-access-token'] =
            response.result?.token;
        dispatch(loginAuth(response?.result));

        setLoading(false);
    }

    async function changedInfoUser() {
        if (userInfo?.user?.platform === 'revenda_prod') {
            const responseVehicle = await updateRevendaPro(userInfo.user.id);
            const responseCustomer = await updateClienteRevendaPro(userInfo.user.id);

            if (responseVehicle.status === 404 || responseCustomer.status === 404) {
                const logoutCodition = { logout: true };
                dispatch(loginAuth(logoutCodition));
            }
        }
    }

    function AppRoutes() {
        return (
            <Navigator screenOptions={screenOptions}>
                <Screen name="DrawerNavigation" component={DrawerNavigation} />
            </Navigator>
        );
    }

    function ClientRoutes() {
        return (
            <Navigator screenOptions={screenOptions}>
                <Screen
                    name="ClientDrawerNavigation"
                    component={ClientDrawerNavigation}
                />
            </Navigator>
        );
    }

    function initialRoutes() {
        return (
            <Navigator screenOptions={screenOptions}>
                <Screen name="LoginScreen" component={LoginScreen} />
                <Screen
                    name="ForgotPasswordScreen"
                    component={ForgotPasswordScreen}
                />
            </Navigator>
        );
    }

    function renderConditionalRoutes() {
        if (loading) {
            return (
                <Loading />
            );
        }
        if (!userInfo || userInfo.logout) {
            return initialRoutes();
        }
        if (userInfo?.user?.type === 'users') {
            // window.location.reload();
            return ClientRoutes();
        }
        if (!userInfo?.user) {
            return initialRoutes();
        }
        return AppRoutes();
    }

    useEffect(() => {
        setTokenDefault();
    }, []);

    useEffect(() => {
        if (userInfo) {
            changedInfoUser();
        }
    }, [userInfo])

    return renderConditionalRoutes();
}
