// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import toast from 'react-hot-toast';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import * as DocumentPicker from 'expo-document-picker';
import { useSelector } from 'react-redux';

import * as S from './styles';
import AddPhoto from '../../../../../assets/images/svg/addPhoto';
import Paperclip from '../../../../../assets/images/svg/paperClip';
import CloseIcon from '../../../../../assets/images/close.png';

import { InputInternal } from '../../../../../components/InputInternal';
import { Buttons } from '../../../../../components/DashboardButtons';
import { getImageLink } from '../../../../../services/uploads';

import { getPlans } from '../../../../../services/plans';
import { editClient, createClient } from '../../../../../services/clients';
import { CoditionModal } from '../../../../../components/CoditionModal';
import { LoadingModal } from '../../../../../components/LoadingModal';
import theme from '../../../../../global/styles/theme';
import { MaskPhone } from '../../../../../global/utils/functions';
import { ConfigDescription } from '../../../../../components/ConfigDescription';

interface GeneralInfoProps {
    setScreenName?: () => void;
    idUser: number;
    info: any;
    typeScreen: 'newClient' | 'editClient' | 'viewClient';
    handleSubmit: any,
    control: any,
    errors: any,
    image: string,
    setImage: (image: string) => void,
    logoList: Array<string>,
    setLogoList: (logo: Array<string>) => void,
    password: string,
    setPassword: (password: string) => void,
    confirmPassword: string,
    setConfirmPassword: (password: string) => void,
    isValid?: boolean,
    getValues: any,
}

interface ImageProps {
    name: string;
    type: string;
    base64: string;
}

interface ModalInfoProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

interface LoadingModalProps {
    visible: boolean,
    title: string,
}

const defaultMessage =
    'Digite sua mensagem seguindo estas instruções: Clique no nome de sua loja, depois na aba perfil e depois no campo "Mensagem" escreva sua mensagem para a arte';

export function GeneralInfo(props: GeneralInfoProps) {
    const {
        info,
        idUser,
        setScreenName,
        typeScreen,
        errors,
        handleSubmit,
        control,
        image,
        setImage,
        logoList,
        setLogoList,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        isValid,
        getValues,
        framesList,
        setFramesList
    } = props;
    const { user } = useSelector((state: any) => state.user.user);
    const navigation = useNavigation();
    const window = useWindowDimensions().width;
    const widthForTwoColumns = window <= 800 ? '100%' : '49%';
    const widthForAddressFirstInput = window <= 800 ? '100%' : '63%';
    const widthForAddressSecondInput = window <= 800 ? '100%' : '35%';
    const widthForThreeColumns = window <= 800 ? '100%' : '32%';
    const widthForFourColumns = window <= 800 ? '100%' : '23.5%';

    const [inputPlans, setInputPlans] = useState('');
    const [plansList, setPlansList] = useState('');
    const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ modalVisible: false } as ModalInfoProps);
    const titleModal = typeScreen === 'editClient' ? 'Editando usuário...' : 'Criando usuário...';
    const [loadingModalVisible, setLoadingModalVisible] = useState<LoadingModalProps>({ visible: false } as LoadingModalProps);
    const cpfOrCnpj = getValues('document');
    const planId = getValues('idPlan');
    const [heightBoxArea, setHeightBoxArea] = useState(200);
    const [isError, setIsError] = useState(false);
    const onPressButton = handleSubmit(handleSave);
    const validationInput = typeScreen === 'viewClient' || user?.platform === 'revenda_prod'
    const inicialMessage = info?.message && info?.message !== defaultMessage ? info?.message : '';

    function formatIdentifier(numberInitial: string) {
        const emptyNumber = numberInitial.replace(/\D/g, '');

        if (emptyNumber.length === 14) {
            return emptyNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else if (emptyNumber.length === 11) {
            return emptyNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (emptyNumber.length > 0) {
            return emptyNumber;
        } else {
            return '';
        }
    }

    const cpfOrCnpjInitial = info?.document ? formatIdentifier(info?.document) : '';

    const MaskTelephone = () => {
        const initialPhone = MaskPhone(info?.phone);
        if (initialPhone.length === 14) {
            return `${initialPhone.substring(0, 9)}-${initialPhone.substring(9, 10)}${initialPhone.slice(-3)}`;
        }
        return initialPhone;
    }

    async function pickImage(type: 'logo' | 'perfil') {
        const result = await DocumentPicker.getDocumentAsync({
            type: 'image/*',
            multiple: type === 'logo',
        });

        if (result.type === 'success') {
            if (type === 'logo') {
                const files: FileList = result.output;

                Promise.all(Object.keys(files).map((_key, i) =>
                    new Promise((resolve) => {
                        const reader = new FileReader();
                        const file = files[i];
                        reader.onload = () => {
                            resolve({
                                base64: reader.result as string,
                                name: file.name,
                                type: file.type,
                            });
                        };
                        reader.readAsDataURL(file);
                    }
                ))).then((result) => {
                    getImageUri(result, type);
                });
            } else {
                const bodyImage = {
                    name: result?.name,
                    type: result?.mimeType,
                    base64: result?.uri,
                };

                getImageUri(bodyImage, type);
            }
        }
    }

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalInfoProps);
    }

    async function getImageUri(body: any, type: 'logo' | 'perfil') {
        const titleModal = type === 'logo' ? 'Salvando imagens...' : 'Salvando imagem...';
        const loadingModalInfo: LoadingModalProps = {
            visible: true,
            title: titleModal,
        };
        setLoadingModalVisible(loadingModalInfo);

        if (type === 'perfil') {
            const { result, status, message } = await getImageLink(body);
            if (status === 200) {
                changeModalLoadingVisible();
                setImage(result);
            } else {
                changeModalLoadingVisible();
                toast.error(message, theme.toast);
            }
        } else {
            Promise.all(body.map(async (el: any) => {
                const { result } = await getImageLink(el);

                return result;
            })).then((result) => {
                changeModalLoadingVisible();
                if (type === 'logo') {
                    setLogoList([...logoList, ...result]);
                } else {
                    setFramesList([...framesList, ...result]);
                }
            }).catch((error) => {
                changeModalLoadingVisible();
                toast.error(`${error}`, theme.toast);
            });
        }
    }

    function goToManageClient() {
        setModalInfo({
            modalVisible: false
        })
        navigation.navigate('ManageClientsScreen');
    }

    function changeModalLoadingVisible() {
        setLoadingModalVisible({ visible: false, title: '' });
    }

    async function handleSave(data: any) {
        const coditionPassword = typeScreen === 'newClient' ? password : true;
        // logoList.length &&
        if (coditionPassword || validationInput) {
            const loadingModalInfo: LoadingModalProps = {
                visible: true,
                title: titleModal,
            }
            setLoadingModalVisible(loadingModalInfo);

            const bodyClientInfo  = typeScreen === 'newClient'
                ? { ...data, blockedAt: data.blockedAt === 'true', numberAddress: data.numberAddress ? Number(data.numberAddress) : '', phone: data.phone === '(' ? '' : data.phone, technicianWhatsapp: data.technicianWhatsapp === '(' ? '' : data.technicianWhatsapp, images: logoList, photo: image, password, frames: framesList, width: Number(data.width), height: Number(data.height) }
                : { ...data, blockedAt: data.blockedAt === 'true', numberAddress: data.numberAddress ? Number(data.numberAddress) : '', phone: data.phone === '(' ? '' : data.phone, technicianWhatsapp: data.technicianWhatsapp === '(' ? '' : data.technicianWhatsapp, images: logoList, photo: image, frames: framesList, width: Number(data.width), height: Number(data.height) };

            const { status, message } =
                typeScreen === 'editClient'
                    ? await editClient(bodyClientInfo, idUser)
                    : await createClient(bodyClientInfo);

            if (status === 201 || status === 200) {
                changeModalLoadingVisible();
                const modalProps: ModalInfoProps = {
                    typeModal: 'success',
                    titleModal: 'Sucesso',
                    descriptionModal: message,
                    buttonText: 'Continuar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: goToManageClient,
                };

                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            } else {
                changeModalLoadingVisible();
                const modalProps: ModalInfoProps = {
                    typeModal: 'error',
                    titleModal: 'Erro',
                    descriptionModal: message,
                    buttonText: 'Fechar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: changeModalInfoVisible,
                };

                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            }
        }
    }

    function RenderImage() {
        if (image) {
            const perfilImage = { uri: image };
            return (
                <S.UserPhoto onContextMenu={(e) => e.preventDefault()} resizeMode="contain" source={perfilImage} />
            );
        }

        return (
            <AddPhoto />
        );
    }

    function goBack() {
        navigation.navigate('ManageClientsScreen')
    }

    function handleLogoPress() {
        pickImage('logo');
    }

    function handlePerfilPress() {
        pickImage('perfil');
    }

    function RenderLogo(type: 'logo') {
        if (type === 'logo' && !logoList.length) {
            return null;
        }

        const list = type === 'logo' ? logoList : framesList;

        return (list || []).map((el) => {
            const uriImage = { uri: el };

            const handleRemoveImage = () => {
                const newArrayList = (list || []).filter((item) => item !== el);

                if (type === 'logo') {
                    setLogoList(newArrayList);
                } else {
                    setFramesList(newArrayList);
                }
            };

            return (
                <S.LogoArea onPress={handleRemoveImage}>
                    <S.Logo source={uriImage} resizeMode="contain" onContextMenu={(e) => e.preventDefault()}>
                        <S.CloseImage onContextMenu={(e) => e.preventDefault()} source={CloseIcon} />
                    </S.Logo>
                </S.LogoArea>
            );
        });
    }

    function RenderPasswordInput() {
        if (typeScreen !== 'newClient') {
            return null;
        }

        return (
            <S.InputContainer width={window}>
                {RenderInputPassword(
                    'Senha*',
                    'Digite a senha',
                    password,
                    setPassword,
                    isError && !password,
                )}
                {RenderInputPassword(
                    'Confirmar senha*',
                    'Digite a senha novamente',
                    confirmPassword,
                    setConfirmPassword,
                    isError && !confirmPassword,
                )}
            </S.InputContainer>
        );
    }

    function RenderInputPassword(
        label: string,
        placeholder: string,
        value: string,
        onChange: (value: string) => void,
        codition: boolean,
    ) {
        return (
            <S.ContainerPassword width={widthForTwoColumns}>
                <S.Label>{label}</S.Label>
                <S.InputContainerPassword error={codition}>
                    <S.InputPassword
                        value={value}
                        onChangeText={onChange}
                        placeholder={placeholder}
                        secureTextEntry={true}
                    />
                </S.InputContainerPassword>
            </S.ContainerPassword>
        );
    }

    async function getArts() {
        const response = await getPlans(1, inputPlans, 'active');

        if (response.status === 200) {
            const firstValue = {
                label: 'Selecione um plano',
                value: '0',
            };
            const listFormatted = response.result?.list.map((e) => {
                const formatted = {
                    label: e.name,
                    value: e.id,
                };
                return formatted;
            });
            setPlansList([firstValue, ...listFormatted]);
        }
    }

    useEffect(() => {
        getArts();
    }, []);

    function RenderButton() {
        if (typeScreen === 'viewClient') {
            return null;
        }

        const handleButtonPress = () => {
            if (user?.platform === 'revenda_prod') {
                const dtCurrent = getValues();
                handleSave(dtCurrent);
                return;
            }

            if (validationInput) {
                setIsError(false);
            } else if (
                !logoList.length ||
                !password ||
                !confirmPassword ||
                password !== confirmPassword
            ) {
                setIsError(true);
            }
            onPressButton();
        }

        return (
            <S.ButtonArea>
                <Buttons
                    buttonConfirmText="Salvar"
                    saveFuntion={handleButtonPress}
                    cancelFuntion={goBack}
                    isValid={true}
                />
            </S.ButtonArea>
        )
    }

    return (
        <S.Form width={window}>
            <S.TextDefault>Informações Básicas</S.TextDefault>
            <S.BasicInfoArea width={window}>
                <S.AddPhotoArea width={window}>
                    <S.PhotoArea>
                        <S.AddButtonPhoto
                            onPress={handlePerfilPress}
                            disabled={typeScreen === 'viewClient'}>
                            {RenderImage()}
                        </S.AddButtonPhoto>
                        <S.AddPhotoText>Adicionar imagem</S.AddPhotoText>
                    </S.PhotoArea>
                </S.AddPhotoArea>
                <S.BasicInfoInputs width={window}>
                    <InputInternal
                        inicialValue={info?.name || ''}
                        width="100%"
                        label="Nome*"
                        placeholder="Digite o nome da loja"
                        control={control}
                        errors={errors}
                        name="name"
                        disabled={validationInput}
                    />
                    <InputInternal
                        inicialValue={info?.email || ''}
                        width="100%"
                        label="Email*"
                        placeholder="Digite o email"
                        control={control}
                        errors={errors}
                        name="email"
                        disabled={validationInput}
                    />
                    {RenderPasswordInput()}
                    <S.InputContainer width={window}>
                        <InputInternal
                            mask="cpf"
                            inicialValue={cpfOrCnpjInitial}
                            width={widthForTwoColumns}
                            cpfOrCnpjNumber={cpfOrCnpj}
                            label="CNPJ/CPF*"
                            placeholder="Insira seu CNPJ/CPF"
                            control={control}
                            errors={errors}
                            name="document"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.state_registration || ''}
                            width={widthForTwoColumns}
                            label="Insc. Estadual (Opcional)"
                            placeholder="Insira sua inscrição estadual"
                            control={control}
                            errors={errors}
                            name="stateRegistration"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.birth_date || ''}
                            width={widthForTwoColumns}
                            label="Aniversário"
                            placeholder="00/00/0000"
                            control={control}
                            errors={errors}
                            name="birthDate"
                            disabled={validationInput}
                            mask="date"
                        />
                        <InputInternal
                            inicialValue={info?.signature || ''}
                            width={widthForTwoColumns}
                            label="Assinatura"
                            placeholder="Assinatura"
                            control={control}
                            errors={errors}
                            name="signature"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    {/* <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.width_template || ''}
                            width={widthForTwoColumns}
                            label="Largura"
                            placeholder="Largura"
                            control={control}
                            errors={errors}
                            name="width"
                            disabled={user?.type !== 'adm'}
                        />
                        <InputInternal
                            inicialValue={info?.height_template || ''}
                            width={widthForTwoColumns}
                            label="Altura"
                            placeholder="Altura"
                            control={control}
                            errors={errors}
                            name="height"
                            disabled={user?.type !== 'adm'}
                        />
                    </S.InputContainer> */}
                    <S.InputContainer width={window}>
                        <S.AdjustBoxArea width={window}>
                            <InputInternal
                                width="100%"
                                label="Texto padrão (Opcional)"
                                placeholder="Insira o texto padrão (Opcional)"
                                control={control}
                                name="defaultText"
                                errors={errors}
                                heightBoxArea={heightBoxArea}
                                inicialValue={info?.default_text || ''}
                                multiline
                            />
                            {heightBoxArea === 200 ? (
                                <S.ResizeBoxArea
                                    onPress={() => setHeightBoxArea(400)}>
                                    <MaterialCommunityIcons
                                        name="arrow-down-drop-circle"
                                        size={18}
                                        color="white"
                                    />
                                </S.ResizeBoxArea>
                            ) : (
                                <S.ResizeBoxArea
                                    onPress={() => setHeightBoxArea(200)}>
                                    <MaterialCommunityIcons
                                        name="arrow-up-drop-circle"
                                        size={18}
                                        color="white"
                                    />
                                </S.ResizeBoxArea>
                            )}
                        </S.AdjustBoxArea>
                        <S.AdjustBoxArea width={window}>
                            <S.InputContainer>
                                <InputInternal
                                    width="100%"
                                    label="Mensagem"
                                    placeholder="Mensagem"
                                    control={control}
                                    name="message"
                                    errors={errors}
                                    heightBoxArea="120px"
                                    inicialValue={inicialMessage || ''}
                                    multiline
                                    maxLength={200}
                                />
                                <S.InputContainer>
                                    <InputInternal
                                        width="100%"
                                        label="Mensagem padrão de aniversário"
                                        placeholder="Mensagem padrão de aniversário"
                                        control={control}
                                        name="birthdayMessage"
                                        errors={errors}
                                        heightBoxArea="120px"
                                        inicialValue={
                                            info?.birthday_message || ''
                                        }
                                        multiline
                                        maxLength={150}
                                        marginTop="16px"
                                    />
                                </S.InputContainer>
                            </S.InputContainer>
                        </S.AdjustBoxArea>
                    </S.InputContainer>
                </S.BasicInfoInputs>
            </S.BasicInfoArea>
                <S.BasicInfoInputs width={window} style={{ marginTop: 40 }}>
                    <ConfigDescription title="Minhas descrições" type="client" idUser={user.id} />
                </S.BasicInfoInputs>
                <S.BasicInfoInputs width={window}>
                    <S.TextDefault marginTop={40}>Informações de contato</S.TextDefault>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.address || ''}
                            width={widthForAddressFirstInput}
                            label="Endereço"
                            placeholder="Digite seu endereço"
                            control={control}
                            errors={errors}
                            name="address"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.cep || ''}
                            width={widthForAddressSecondInput}
                            label="CEP"
                            placeholder="Digite seu CEP"
                            control={control}
                            errors={errors}
                            name="cep"
                            mask="zip-code"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.number_address || ''}
                            width={widthForThreeColumns}
                            label="Número"
                            placeholder="Digite o número da sua residência"
                            control={control}
                            errors={errors}
                            name="numberAddress"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.complement || ''}
                            width={widthForThreeColumns}
                            label="Complemento (Opcional)"
                            placeholder="Digite o complemento"
                            control={control}
                            errors={errors}
                            name="complement"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.district || ''}
                            width={widthForThreeColumns}
                            label="Bairro"
                            placeholder="Digite seu bairro"
                            control={control}
                            errors={errors}
                            name="district"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.city || ''}
                            width={widthForTwoColumns}
                            label="Cidade"
                            placeholder="Digite o nome da sua cidade"
                            control={control}
                            errors={errors}
                            name="city"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.state_address || ''}
                            width={widthForTwoColumns}
                            label="Estado (UF)"
                            placeholder="Digite o estado (UF)"
                            control={control}
                            errors={errors}
                            name="stateAddress"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={MaskTelephone(info?.phone) || ''}
                            width={widthForTwoColumns}
                            label="Telefone"
                            placeholder="(__) ____-____"
                            control={control}
                            errors={errors}
                            name="phone"
                            mask={info?.phone?.length === 14 ? 'custom' : 'cel-phone'}
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.whatsapp || ''}
                            width={widthForTwoColumns}
                            label="Whatsapp (Opcional)"
                            placeholder="(__) ____-____"
                            control={control}
                            errors={errors}
                            name="whatsapp"
                            mask="cel-phone"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    <S.TextDefault marginTop={40}>Informações técnicas</S.TextDefault>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.technician_name || ''}
                            width={widthForThreeColumns}
                            label="Responsável técnico"
                            placeholder="Digite o nome do resp. técnico"
                            control={control}
                            errors={errors}
                            name="technicianName"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.technician_email || ''}
                            width={widthForThreeColumns}
                            label="Email"
                            placeholder="Digite o email do resp. técnico"
                            control={control}
                            errors={errors}
                            name="technicianEmail"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={MaskPhone(info?.technician_whatsapp) || ''}
                            width={widthForThreeColumns}
                            label="Whatsapp"
                            placeholder="(__) ____-____"
                            control={control}
                            errors={errors}
                            name="technicianWhatsapp"
                            mask="cel-phone"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                    <S.TextDefault marginTop={40}>Redes sociais</S.TextDefault>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.site || ''}
                            width={widthForFourColumns}
                            label="Site (Opcional)"
                            placeholder="Insira um link"
                            control={control}
                            errors={errors}
                            name="site"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.instagram || ''}
                            width={widthForFourColumns}
                            label="Instagram (Opcional)"
                            placeholder="Digite seu @"
                            control={control}
                            errors={errors}
                            name="instagram"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.facebook || ''}
                            width={widthForFourColumns}
                            label="Facebook (Opcional)"
                            placeholder="Digite seu @"
                            control={control}
                            errors={errors}
                            name="facebook"
                            disabled={validationInput}
                        />
                        <InputInternal
                            inicialValue={info?.tiktok || ''}
                            width={widthForFourColumns}
                            label="TikTok (Opcional)"
                            placeholder="Digite seu @"
                            control={control}
                            errors={errors}
                            name="tiktok"
                            disabled={validationInput}
                        />
                    </S.InputContainer>
                </S.BasicInfoInputs>
                <S.BasicInfoInputs width={window}>
                    <S.TextDefault marginTop={40}>Mídias</S.TextDefault>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.id_plan || '0'}
                            width={'100%'}
                            label="Planos*"
                            placeholder="Selecione seu plano"
                            control={control}
                            errors={errors}
                            name="idPlan"
                            list={plansList}
                            errorCodition={planId === '0' && isError}
                            disabled={validationInput || user?.type !== 'adm'}
                        />
                    </S.InputContainer>
                    <S.InputContainer width={window}>
                        <InputInternal
                            inicialValue={info?.buy_limit || ''}
                            width={widthForFourColumns}
                            label="Limite de Compras por mês*"
                            placeholder="Digite o Limite de Compras"
                            control={control}
                            errors={errors}
                            name="buyLimit"
                            disabled={validationInput || user?.type !== 'adm'}
                        />
                        <S.AlignLogoArea width={window} validationInput={validationInput}>
                            <S.Label>Logotipos</S.Label>
                            <S.LogoButton
                                // error={isError && !logoList.length}
                                onPress={handleLogoPress}
                                disabled={validationInput}>
                                <Paperclip />
                                <S.LogoText>Adicione suas imagens</S.LogoText>
                            </S.LogoButton>
                            <S.LogoAlign>{RenderLogo('logo')}</S.LogoAlign>
                        </S.AlignLogoArea>
                    </S.InputContainer>
                </S.BasicInfoInputs>
                {idUser && (
                    <S.BasicInfoInputs width={window}>
                        <S.TextDefault marginTop={40}>Configurações avançadas</S.TextDefault>
                        <S.InputContainer width={window}>
                            <InputInternal
                                inicialValue={!!info?.blocked_at}
                                width={'23.5%'}
                                label="Bloquear cliente do sistema"
                                placeholder="Selecione uma opção"
                                control={control}
                                errors={errors}
                                name="blockedAt"
                                list={[{ label: 'Não', value: false }, { label: 'Sim', value: true }]}
                            />
                        </S.InputContainer>
                    </S.BasicInfoInputs>
                )}
            <CoditionModal
                titleModal={modalInfo.titleModal}
                descriptionModal={modalInfo.descriptionModal}
                typeModal={modalInfo.typeModal}
                buttonText={modalInfo.buttonText}
                modalVisible={modalInfo.modalVisible}
                setModalVisible={modalInfo.setModalVisible}
                buttonFunction={modalInfo.buttonFunction}
            />
            <LoadingModal
                visible={loadingModalVisible.visible}
                titleLoading={loadingModalVisible.title}
            />
            {RenderButton()}
        </S.Form>
    );
}
