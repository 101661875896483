function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React from "react";
import * as d3Ease from "victory-vendor/d3-ease";
import { victoryInterpolator } from "./util";
import TimerContext from "../victory-util/timer-context";
/**
 * Single animation object to interpolate
 */

/** d3-ease changed the naming scheme for ease from "linear" -> "easeLinear" etc. */
var formatAnimationName = function (name) {
  var capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  return "ease".concat(capitalizedName);
};

var DEFAULT_DURATION = 1000;
export var VictoryAnimation = function (_ref) {
  var _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? DEFAULT_DURATION : _ref$duration,
      _ref$easing = _ref.easing,
      easing = _ref$easing === void 0 ? "quadInOut" : _ref$easing,
      _ref$delay = _ref.delay,
      delay = _ref$delay === void 0 ? 0 : _ref$delay,
      data = _ref.data,
      children = _ref.children,
      onEnd = _ref.onEnd;

  var _React$useState = React.useState({
    data: Array.isArray(data) ? data[0] : data,
    animationInfo: {
      progress: 0,
      animating: false
    }
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      state = _React$useState2[0],
      setState = _React$useState2[1];

  var timer = React.useContext(TimerContext).animationTimer;
  var queue = React.useRef(Array.isArray(data) ? data.slice(1) : []);
  var interpolator = React.useRef(null);
  var loopID = React.useRef(undefined);
  var ease = d3Ease[formatAnimationName(easing)];
  React.useEffect(function () {
    // Length check prevents us from triggering `onEnd` in `traverseQueue`.
    if (queue.current.length) {
      traverseQueue();
    } // Clean up the animation loop


    return function () {
      if (loopID.current) {
        timer.unsubscribe(loopID.current);
      } else {
        timer.stop();
      }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(function () {
    // If the previous animation didn't finish, force it to complete before starting a new one
    if (interpolator.current && state.animationInfo && state.animationInfo.progress < 1) {
      setState({
        data: interpolator.current(1),
        animationInfo: {
          progress: 1,
          animating: false,
          terminating: true
        }
      });
    } else {
      // Cancel existing loop if it exists
      timer.unsubscribe(loopID.current); // Set the tween queue to the new data

      queue.current = Array.isArray(data) ? data : [data]; // Start traversing the tween queue

      traverseQueue();
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [data]);

  var traverseQueue = function () {
    if (queue.current.length) {
      var nextData = queue.current[0]; // Compare cached version to next props

      interpolator.current = victoryInterpolator(state.data, nextData); // Reset step to zero

      if (delay) {
        setTimeout(function () {
          loopID.current = timer.subscribe(functionToBeRunEachFrame, duration);
        }, delay);
      } else {
        loopID.current = timer.subscribe(functionToBeRunEachFrame, duration);
      }
    } else if (onEnd) {
      onEnd();
    }
  };

  var functionToBeRunEachFrame = function (elapsed) {
    if (!interpolator.current) return; // Step can generate imprecise values, sometimes greater than 1
    // if this happens set the state to 1 and return, cancelling the timer

    var step = duration ? elapsed / duration : 1;

    if (step >= 1) {
      setState({
        data: interpolator.current(1),
        animationInfo: {
          progress: 1,
          animating: false,
          terminating: true
        }
      });

      if (loopID.current) {
        timer.unsubscribe(loopID.current);
      }

      queue.current.shift();
      traverseQueue();
      return;
    } // If we're not at the end of the timer, set the state by passing
    // current step value that's transformed by the ease function to the
    // interpolator, which is cached for performance whenever props are received


    setState({
      data: interpolator.current(ease(step)),
      animationInfo: {
        progress: step,
        animating: step < 1
      }
    });
  };

  return children(state.data, state.animationInfo);
};